export const content = {
    mainHeadLineBig: "Bodo Wissemann - Fullstack Software Engineer",
    mainHeadLineSmall: "Bodo Wissemann",
    skillsHeadlineEn: "Languages & Frameworks",
    skills: [
        "Kotlin",
        "Java",
        "Typescript",
        "Javascript",
        "Spring Boot",
        "NodeJS",
        "React",
        "PostgreSQL",
        "AWS",
        "docker",
        "kubernetes",
        "terraform",
        "GIT",
        "Agile"
    ],
    skillLogosRound: [
        {
            name: "Kotlin",
            fileName: "/images/rounded/kotlinRounded.png",
            percentage: 100
        },
        {
            name: "Spring Boot",
            fileName: "/images/rounded/springBootRounded.png",
            percentage: 95
        },
        {
            name: "Typescript",
            fileName: "/images/rounded/typeScriptRounded.png",
            percentage: 91
        },
        {
            name: "React",
            fileName: "/images/rounded/reactRounded.png",
            percentage: 86
        },
        {
            name: "Java",
            fileName: "/images/rounded/javaRounded.png",
            percentage: 82
        },
        {
            name: "SQL",
            fileName: "/images/rounded/sqlRounded.png",
            percentage: 77.5
        },
        {
            name: "GIT",
            fileName: "/images/rounded/gitRounded.png",
            percentage: 72.6
        },
        {
            name: "Docker",
            fileName: "/images/rounded/dockerRounded.png",
            percentage: 69
        },
        {
            name: "NodeJS",
            fileName: "/images/rounded/nodeJsRounded.png",
            percentage: 63.5
        },
        {
            name: "Gradle",
            fileName: "/images/rounded/gradleRounded.png",
            percentage: 60
        },
        {
            name: "AWS",
            fileName: "/images/rounded/awsRounded.png",
            percentage: 54
        },
        {
            name: "NoSQL",
            fileName: "/images/rounded/noSqlRounded.png",
            percentage: 50
        },
        {
            name: "DynamoDB",
            fileName: "/images/rounded/dynamoDB.png",
            percentage: 43
        },
        {
            name: "Maven",
            fileName: "/images/rounded/mavenRounded.png",
            percentage: 38
        },
        {
            name: "React Native",
            fileName: "/images/rounded/reactNative.png",
            percentage: 30
        },
        {
            name: "Serverless",
            fileName: "/images/rounded/serverless.png",
            percentage: 25
        },
        {
            name: "Solidity",
            fileName: "/images/rounded/solidityRounded.png",
            percentage: 17
        },
        {
            name: "Elastic Search",
            fileName: "/images/rounded/elasticsearch.png",
            percentage: 13
        },
        {
            name: "Terraform",
            fileName: "/images/rounded/terraformRounded.png",
            percentage: 9
        },
        {
            name: "Kubernetes",
            fileName: "/images/rounded/kubernetes.png",
            percentage: 5
        },
    ],
    skillDescriptions: [
        {
            name: "react",
            fileName: "reactRounded.png",
        },
        {
            name: "java",
            fileName: "javaRounded.png",
        },
        {
            name: "kotlin",
            fileName: "kotlinRounded.png",
        },
        {
            name: "typescript",
            fileName: "typeScriptRounded.png",
        },
        {
            name: "spring_boot",
            fileName: "springBootRounded.png",
        },
        {
            name: "node_js",
            fileName: "nodeJsRounded.png",
        },
        {
            name: "aws",
            fileName: "awsRounded.png",
        },
        {
            name: "SQL",
            fileName: "sqlRounded.png",
        }
    ],
    letterHeadlineEn: "About",
    letterEn: `I"m a father, a husband and a dedicated fullstack web-developer with focus on backend who never stops learning. 
            I am experienced in microservice-architectures, asynchronous data-flows and different databases. 
            I write a lot of tests.
            `,
    contact: {
        headlineEn: "Reach Me",
        name: "Bodo Wissemann",
        street: "Hasenweg 11k",
        city: "14552 Michendorf",
        email: "bodo.wissemann.engineering@posteo.de",
        tel: "+49 176 63832559",
        cv: "Download CV",
        cvPath: "/CV_BodoWissemann_2023.pdf",
        cvName: "CV-BodoWissemann.pdf"
    },
    experienceHeadLineEn: "Jobs and Experiences",
    experiences: [
        {
            company: "upday for Samsung",
            logo: "/images/companyLogos/upday.png",
            role: "Backend Software Engineer",
            dateRange: "Apr 2021 - today",
            location: "Berlin",
            technologies: [
                "Kotlin",
                "Java",
                "SpringBoot",
                "PostgreSQL",
                "AWS",
                "Kubernetes",
                "Terraform",
                "ElasticSearch"
            ],
            text: `I was working in an agile team on upday's core backend.
            Part of my work was setting up new microservices, implementing
            features, defining requirements, testing and aligning with po's.`
        },
        {
            company: "MHP",
            logo: "/images/companyLogos/mhp.png",
            role: "Consultant / Fullstack Software Engineer",
            dateRange: "Aug 2018 - Mar 2021",
            location: "Berlin",
            technologies: [
                "Kotlin",
                "Typescript",
                "SpringBoot",
                "Nodejs",
                "PostgreSQL",
                "AWS",
                "Docker",
                "Serverless"
            ],
            text: `Working in different projects for Porsche Digital, VW, Aston Martin & Audi. We built various applications such as a skill matching tool for Audi-Employees,
            quality-ensurance software for Porsche to stress-test technical parts during development or an application to handle payments
            for electric car-sharing, wallboxes and e-charging for Audi/VW.`
        },
        {
            company: "wysker UG",
            logo: "/images/companyLogos/wysker.png",
            role: "Fullstack Software Engineer",
            dateRange: "Apr 2018 - Jul 2018",
            location: "Berlin",
            technologies: [
                "Javascript",
                "NodeJS",
                "JQuery",
                "docker",
                "PostgreSQL",
                "docker"
            ],
            text: `Fullstack developer working on front- and backend.
            I was developing the companies homepage as well as the inhouse CMS to manage and create content.`
        },
        {
            company: "adesso AG",
            logo: "/images/companyLogos/adesso.png",
            role: "Working Student Software Engineering",
            dateRange: "Nov 2016 - Mar 2018",
            location: "Berlin",
            technologies: [
                "Xquery",
                "Javascript",
                "JQuery",
                "XML",
                "Docker",
                "bpmn"
            ],
            text: `Working on front- and backend on a workflow-engine called 'influx'. The software was used by our customers to setup
            bpmn-workflows and handle the communication between the participants involved in the workflow in an automated way.`
        }
    ]
}