import * as Phaser from 'phaser';
import { BACKGROUND_COLOR_DARK } from '../../Config';
import { SkillRayScene } from './SkillRayScene';

export const skillRayConfig: Phaser.Types.Core.GameConfig = {
  title: 'Sample',
  type: Phaser.AUTO,
  scale: {
    parent: "PHASER_WRAPPER",
    mode: Phaser.Scale.RESIZE,
    autoCenter: Phaser.Scale.CENTER_BOTH,
    width: 1000,
    height: 500,
  },
  render: {
    roundPixels: true,
  },
  pixelArt: true,
  scene: SkillRayScene,
  backgroundColor: BACKGROUND_COLOR_DARK,
};
