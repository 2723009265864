import { Checkbox, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from "react";
import { FullScreenCard } from "."
import { BORDER_COLOR } from '../Config';
import { content } from "../Content"
import { isMobile } from "../util";
import { Experience } from "./Experience"
import { HeadLine } from "./HeadLine";
import { Brightness1, Brightness1Outlined } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
    experienceWrapper: {
        position: 'relative',
        height: '65vh',
        marginTop: -theme.spacing(8)
    },
    mobileExperience: {
        transition: `transform ease-in 0.1s, opacity ease-in 0.1s`
    },
    mobileExperienceInvisible: {
        position: 'absolute',
        opacity: '0'
    },
    mobileExperienceVisible: {
        position: 'relative',
        opacity: '1',
        transform: `translateX(0vw)`
    },
    left1X: {
        transform: `translateX(-100vw)`
    },
    left2X: {
        transform: `translateX(-200vw)`
    },
    left3X: {
        transform: `translateX(-300vw)`
    },
    right1X: {
        transform: `translateX(100vw)`
    },
    right2X: {
        transform: `translateX(200vw)`
    },
    right3X: {
        transform: `translateX(300vw)`
    },
    footerWrapper: {
        height: "10vh"
    },
    footer: {
        borderTop: `0.5px solid ${BORDER_COLOR}`
    },
    dot: {
        width: "25%",
    },
    icon: {
        color: BORDER_COLOR,
        height: "60%"
    }
}));

export const Experiences: React.FC = () => {
    const classes = useStyles()

    const [area, setArea] = useState(0)

    let isSwiping = false

    const classesArrays = [
        [
            `${classes.mobileExperienceVisible}`,
            `${classes.mobileExperienceInvisible} ${classes.right1X}`,
            `${classes.mobileExperienceInvisible} ${classes.right2X}`,
            `${classes.mobileExperienceInvisible} ${classes.right3X}`
        ],
        [
            `${classes.mobileExperienceInvisible} ${classes.left1X}`,
            `${classes.mobileExperienceVisible}`,
            `${classes.mobileExperienceInvisible} ${classes.right1X}`,
            `${classes.mobileExperienceInvisible} ${classes.right2X}`
        ],
        [
            `${classes.mobileExperienceInvisible} ${classes.left2X}`,
            `${classes.mobileExperienceInvisible} ${classes.left1X}`,
            `${classes.mobileExperienceVisible}`,
            `${classes.mobileExperienceInvisible} ${classes.right1X}`,
        ],
        [
            `${classes.mobileExperienceInvisible} ${classes.left3X}`,
            `${classes.mobileExperienceInvisible} ${classes.left2X}`,
            `${classes.mobileExperienceInvisible} ${classes.left1X}`,
            `${classes.mobileExperienceVisible}`
        ],
    ]

    useEffect(() => {
        const wrapper = document.getElementsByClassName(classes.experienceWrapper)[0] as HTMLElement

        const wrapperHammertime = new Hammer(wrapper, {
            recognizers: [
                [Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }],
            ]
        })

        wrapperHammertime.on("panleft panright", handleSwipe)
    })

    const handleSwipe = (e: HammerInput) => {
        if (!isSwiping) {
            isSwiping = true
            if (e.direction === 2) {
                setArea(Math.max(area, 0) + 1)
            } else if (e.direction === 4) {
                setArea(Math.min(area, 4) - 1)
            }
        }
    }

    function getClassForIndex(index: number) {
        const nextArea = Math.min(Math.max(area, 0), 3)
        return isMobile() ? `${classes.mobileExperience} ${classesArrays[nextArea][index]}` : ""
    }

    return (
        <FullScreenCard >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <HeadLine>{
                    content.experienceHeadLineEn
                }</HeadLine>
            </Grid>
            <Grid
                item
                container
                className={classes.experienceWrapper}
                xs={12} sm={12} md={12} lg={12} xl={12} direction='row' >
                <Experience
                    additionalClassName={getClassForIndex(0)}
                    content={content.experiences[0]} />
                <Experience
                    additionalClassName={getClassForIndex(1)}
                    content={content.experiences[1]} />
                <Experience
                    additionalClassName={getClassForIndex(2)}
                    content={content.experiences[2]} />
                <Experience
                    additionalClassName={getClassForIndex(3)}
                    content={content.experiences[3]} />
            </Grid>
            <Grid item className={classes.footerWrapper} container flexDirection={"row"} alignItems={"flex-start"} justifyContent="center" xs={12} sm={12} md={12} lg={12} xl={12}>
                {
                    isMobile() && <>
                        <Grid item container justifyContent={"space-around"} xs={5} sm={5} md={5} >
                            <Checkbox
                                checked={area === 0}
                                className={classes.dot}
                                icon={<Brightness1Outlined className={classes.icon} />}
                                checkedIcon={<Brightness1 className={classes.icon} />}
                            />
                            <Checkbox
                                checked={area === 1}
                                className={classes.dot}
                                icon={<Brightness1Outlined className={classes.icon} />}
                                checkedIcon={<Brightness1 className={classes.icon} />}
                            />
                            <Checkbox
                                checked={area === 2}
                                className={classes.dot}
                                icon={<Brightness1Outlined className={classes.icon} />}
                                checkedIcon={<Brightness1 className={classes.icon} />}
                            />
                            <Checkbox
                                checked={area === 3}
                                className={classes.dot}
                                icon={<Brightness1Outlined className={classes.icon} />}
                                checkedIcon={<Brightness1 className={classes.icon} />}
                            />
                        </Grid>
                        <Grid item className={classes.footer} xs={10} sm={10} md={10} lg={10} xl={10} />
                    </>
                }
            </Grid>
        </FullScreenCard>
    )
}