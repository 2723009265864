
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

type HeadlineProps = {
    textAlign?: "left" | "center";
};

export const HeadLine: React.FC<HeadlineProps> = ({ children, textAlign = "center" }) => {
    const useStyles = makeStyles((theme) => ({
        headLine: {
            marginTop: theme.spacing(7),
            marginBottom: -theme.spacing(1),
            textAlign: textAlign
        }
    }));

    const classes = useStyles()

    return (
        <Typography className={classes.headLine} variant='h5'>{children}</Typography>
    )
}