import { Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FullScreenCard, Header } from "."
import { BORDER_COLOR } from '../Config';
import { content } from "../Content"
import { isMobile } from '../util';
import { HeadLine } from "./HeadLine";

const useStyles = makeStyles((theme: any) => ({
    contactButtom: {
        paddingLeft: 0,
        paddingTop: theme.spacing(3),
        minWidth: "100px",
        textAlign: "right",
        textDecoration: 'none',
        color: BORDER_COLOR,
        fontSize: "1.3em"
    }
}));


export const Contact: React.FC = () => {
    const isLandscape = useMediaQuery('(orientation:landscape)')
    const classes = useStyles()
    return (
        <>
            {
                (!isMobile() || (isMobile() && !isLandscape)) && <Header name={"Home"} path={"/"} />
            }
            <FullScreenCard >
                <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <HeadLine textAlign={"left"}>{
                        content.contact.headlineEn
                    }</HeadLine>
                </Grid>
                <Grid
                    height={"70vh"}
                    item={true}
                    container
                    flexDirection={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    xs={12} sm={12} md={12} lg={12} xl={12} direction='row' >
                    <Typography fontSize={"1.25em"}>
                        {
                            content.contact.name
                        }
                    </Typography>
                    <Typography fontSize={"1em"}>
                        {
                            //content.contact.street
                        }
                    </Typography>
                    <Typography fontSize={"1em"}>
                        {
                            //content.contact.city
                        }
                    </Typography>
                    <Typography fontSize={"1em"}>
                        {
                            content.contact.email
                        }
                    </Typography>
                    {
                        <Link className={classes.contactButtom} href={content.contact.cvPath} download={content.contact.cvName}>
                            {
                                content.contact.cv
                            }
                        </Link>
                    }
                </Grid>
            </FullScreenCard>
        </>

    )
}