import { isMobile } from "../util";
import { makeStyles } from '@mui/styles';
import { Card, Grid, CardHeader, Avatar, CardContent, Typography } from "@mui/material";
import { BORDER_COLOR } from "../Config";

type ExperienceProp = {
  content: ContentProp;
  additionalClassName: string;
}

type ContentProp = {
  company: string,
  logo: string;
  role: string;
  dateRange: string;
  location: string;
  technologies?: string[];
  text: string;
}

export const Experience: React.FC<ExperienceProp> = ({ content, additionalClassName }) => {
  const { company, logo, role, dateRange, location, text } = content

  const useStyles = makeStyles((theme) => ({
    experienceContent: {
      width: '100%',
      display: "flex",
      flexDirection: 'column',
      justifyContent: 'space-around',
      boxSizing: 'border-box',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      marginBottom: isMobile() ? '40vh' : 'auto',
      height: isMobile() ? '60vh' : 'auto',
      border: "1px solid #343434",
      borderRadius: "20px"
    },
    cardHeader: {
      padding: theme.spacing(1),
      maxHeight: '80px'
    },
    avatar: {
      width: '50px',
      height: '50px',
      marginLeft: theme.spacing(1)
    },
    chip: {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      minWidth: '120px'
    },
    technologies: {
      display: "flex",
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'row', //TODO make conditional for mobile
    },
    role: {
      marginBottom: theme.spacing(1)
    }
  }));

  const classes = useStyles()

  return (
    <Card className={`${classes.experienceContent} ${additionalClassName}`}>
      <Grid item={true} container xs={12} sm={12} md={12} lg={12} xl={12}>
        <CardHeader
          align='left'
          avatar={
            <Avatar
              className={classes.avatar}
              alt=""
              src={logo}
            />
          }
          className={classes.cardHeader}
          title={`${role} | ${company}, ${location}`}
          subheader={<Typography color={BORDER_COLOR}>{dateRange}</Typography>}
        />
      </Grid>
      <Grid item={true} container xs={12} sm={12} md={12} lg={12} xl={12}>
        <CardContent>
          <Typography variant="body2" align="left">
            {
              text
            }
          </Typography>
        </CardContent>
      </Grid>
    </Card >
  )
}