import { useMediaQuery, Grid, CardMedia } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: theme.spacing(7),
        display: "flex",
        aspectRatio: "1 / 1",
        borderRadius: 500,
        margin: 0
    },
    cardDetails: {
        flex: 1,
    }
}));

export const Photo: React.FC = () => {
    const classes = useStyles();
    const isPortrait = useMediaQuery('(orientation:portrait)')

    return (
        <Grid item={true} xs={isPortrait ? 10 : 4} sm={isPortrait ? 9 : 6} md={5} lg={6} xl={6}>
            <CardMedia image={'images/portrait.jpg'} className={classes.card}/>
        </Grid>
    );
}