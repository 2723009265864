import React, { useEffect } from "react";
import Hammer from "hammerjs";
import { isMobile } from "../util";
import { createTheme } from "@mui/material/styles";
import { Container, CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { BACKGROUND_COLOR_DARK, BACKGROUND_COLOR_NORMAL, BORDER_COLOR } from "../Config";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Contact } from "../components/Contact";
import { MainContent } from "../components/MainContent";

export const MainContainer: React.FC = () => {
  const isLandscape = useMediaQuery('(orientation:landscape)')

  const theme = createTheme({
    palette: {
      primary: blueGrey,
      divider: BORDER_COLOR,
      background: {
        default: BACKGROUND_COLOR_NORMAL,
        paper: BACKGROUND_COLOR_DARK,
      },
      text: {
        primary: '#d1d4d9',
      },
    }
  });

  let i: number = 0
  const handleScroll = (e: HammerInput) => {
    e.preventDefault()
    const main = document.querySelector('#MainContainer')! as HTMLElement
    if (e.direction === Hammer.DIRECTION_UP && i < 2) {
      i += 1
    } else if (e.direction === Hammer.DIRECTION_DOWN && i > 0) {
      i -= 1
    }
    main.style.transform = `translateY(-${i}00vh)`
  }

  useEffect(() => {
    const body = document.body
    const hammertime = new Hammer(body, {
      recognizers: [
        [Hammer.Swipe, { direction: Hammer.DIRECTION_VERTICAL }],
      ]
    })
    hammertime.on("swipeup swipedown", e => {
      isMobile() && !isLandscape && handleScroll(e)
    })
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        color={BACKGROUND_COLOR_DARK}
        maxWidth="lg"
        id="MainContainer"
        style={{ height: '100vh', transition: "transform 280ms ease-out 50ms" }}
      >
        {
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainContent />} />
              <Route path="contact" element={<Contact />} />
            </Routes>
          </BrowserRouter>
        }
      </Container>
    </ThemeProvider>
  );
};

