import { useMediaQuery, AppBar, Toolbar, Typography, Button } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";
import { BORDER_COLOR } from "../Config";
import { content } from "../Content";

type HeaderProps = {
    name: string;
    path: string;
}

const useStyles = makeStyles((theme: any) => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarTitle: {
        flex: 1,
    },
    toolbarSecondary: {
        overflowX: 'auto',
    },
    contactButtom: {
        minWidth: "100px",
        textAlign: "right",
        textDecoration: 'none',
        color: BORDER_COLOR
    }
}));

const { mainHeadLineBig, mainHeadLineSmall } = content

export const Header: React.FC<HeaderProps> = ({ name, path }) => {
    const classes = useStyles()

    const headLine = useMediaQuery('(min-width:600px)') ? mainHeadLineBig : mainHeadLineSmall

    return (
        <AppBar style={{ boxShadow: 'none' }} color={'inherit'} position="fixed">
            <Toolbar className={classes.toolbar}>
                <Typography
                    component="h2"
                    variant="h5"
                    color="inherit"
                    align="center"
                    noWrap
                    className={classes.toolbarTitle}
                >
                    {headLine}
                </Typography>
                <Button>
                    <Link className={classes.contactButtom} to={path}>{name}</Link>
                </Button>
            </Toolbar>
        </AppBar>
    );
}
