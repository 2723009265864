import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { content } from '../Content';

const useStyles = makeStyles(theme => ({
    blockSentence: {
        marginTop: theme.spacing(7),
        hyphens: "auto",
        textAlign: "justify"
    }
}))

export const Letter: React.FC = () => {
    const classes = useStyles()

    return (
        <Grid container>
            <Typography className={classes.blockSentence}>{
                content.letterEn
            }</Typography>
        </Grid>
    )
}
