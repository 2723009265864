import { FullScreenCard, Photo, SkillRay } from "."
import { content } from "../Content";
import { isMobile } from "../util";
import { makeStyles } from '@mui/styles';
import { HeadLine } from "./HeadLine";
import { Letter } from "./Letter";
import { useMediaQuery, Grid } from "@mui/material";

export const PersonalInformation: React.FC = () => {
    const isLandscape = useMediaQuery('(orientation:landscape)')
    const isBigScreen = useMediaQuery('(min-width:960px)')

    const shouldJustifyForLandscape = isBigScreen || (isMobile() && isLandscape)

    const justifyPhoto = shouldJustifyForLandscape ? 'flex-start' : 'center'
    const justifyText = shouldJustifyForLandscape ? 'flex-end' : 'center'


    const useStyles = makeStyles((theme) => ({
        marginAndPadding: {
            marginBottom: theme.spacing(5)
        },
        justifyPhoto: {
            display: 'flex',
            justifyContent: justifyPhoto
        },
        justifyText: {
            display: 'flex',
            justifyContent: justifyText
        }
    }));
    const classes = useStyles()

    return (
        <FullScreenCard justifyContent={justifyPhoto} >
            <Grid
                item={true}
                className={`${classes.marginAndPadding} ${classes.justifyPhoto}`}
                xs={shouldJustifyForLandscape ? 6 : 9}
                sm={shouldJustifyForLandscape ? 6 : 7}
                md={6}
                lg={6}
                xl={6}>
                <Photo />
            </Grid>
            <Grid
                item={true}
                className={classes.marginAndPadding}
                xs={shouldJustifyForLandscape ? 6 : 12}
                sm={shouldJustifyForLandscape ? 6 : 12}
                md={6}
                lg={6}
                xl={6}>
                    <HeadLine textAlign={"left"}>{content.letterHeadlineEn}</HeadLine>
                    <Letter />
            </Grid>
            {
                !isMobile() && <SkillRay />
            }
        </FullScreenCard>
    )
}