import React from "react";
import { isMobile } from "../util";
import { makeStyles } from "@mui/styles";
import { Grid, Card, useMediaQuery } from "@mui/material";

type FullScreenCardProps = {
    children: React.ReactNode;
    justifyContent?: "center" | "flex-start" | "flex-end"
    isVisible?: boolean,
};

export const FullScreenCard: React.FC<FullScreenCardProps> = ({ children, justifyContent}) => {
    const isLandscape = useMediaQuery('(orientation:landscape)')
    const useStyles = makeStyles((theme) => ({
        card: {
            position: "relative",
            justifyContent: justifyContent || "flex-start",
            display: "flex",
            boxShadow: "none",
            flexWrap: "wrap",
            padding: isMobile() ? theme.spacing(2) : theme.spacing(10),
            paddingBottom: theme.spacing(3),
            height: "100vh",
            overflowY: isMobile() || isLandscape ? "hidden":  "scroll",
            overflowX: "hidden"
        }
    }));
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card className={classes.card}>
                {children}
            </Card>
        </Grid>
    );
}
