import { Grid, Chip, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FullScreenCard } from ".";
import { BACKGROUND_COLOR_NORMAL, BORDER_COLOR } from "../Config";
import { content } from "../Content";
import { HeadLine } from "./HeadLine";
import { RoundImageType } from "./skillVisualization/SkillRayScene";

const useStyles = makeStyles((theme) => ({
    marginAndPadding: {
        marginBottom: theme.spacing(6),
        display: "flex",
        alignItems: "flex-end",
        alignContent: "flex-end",
        justifyContent: "center",
        flexFlow: "row-wrap"
    },
    chip: {
        backgroundColor: BORDER_COLOR,
        color: BACKGROUND_COLOR_NORMAL,
        width: "auto",
        margin: theme.spacing(0.5),
        borderRadius: "50px"
    }
}));

export const SkillsSimple: React.FC = () => {
    const classes = useStyles()

    function getStyle(logo: RoundImageType) {
        return {
            border: `${20 * (logo.percentage / 100)}px solid ${BORDER_COLOR}`,
            fontSize: `${Math.max(0.7, 0.2 + (logo.percentage / 100))}em`,
            opacity: 0.5 + (logo.percentage / 100)
        }
    }

    return (
        <FullScreenCard >
            <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                <HeadLine >{content.skillsHeadlineEn}</HeadLine>
            </Grid>
            <Grid
                item={true}
                container
                className={`${classes.marginAndPadding}`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}>
                {
                    content.skillLogosRound.map(logo => {
                        return <Chip
                            key={`chip_${logo.name}`}
                            style={getStyle(logo)}
                            avatar={<Avatar src={`${logo.fileName}`} />}
                            className={classes.chip} label={logo.name} />
                    })
                }
            </Grid>
        </FullScreenCard>
    )
}