import { MainContainer } from './website/Main';
import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

function App() {
  return (
    <div className="App">
      <MainContainer/>
    </div>
  );
}

export default App;
