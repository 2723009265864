
import { Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useEffect, useRef } from "react";
import { BORDER_COLOR } from "../../Config";
import { isMobile } from "../../util";
import { skillRayConfig } from "./PhaserConfig";

export const SkillRay: React.FC = () => {

    const gameRef = useRef<Phaser.Game>()

    useEffect(() => {
        if (!gameRef.current && !isMobile()) {
            const phaserGame = new Phaser.Game(skillRayConfig);
            gameRef.current = phaserGame
        }
    })

    const useStyles = makeStyles((theme) => ({
        phaserCanvas: {
            width: "98%",
            height: "96%",
            marginLeft: "1%",
            marginTop: "1%",
            backgroundColor: "transparent",
        },
        wrapper: {
            display: 'flex',
            margin: theme.spacing(2),
            backgroundColor: "transparent",
            border: `1px solid ${BORDER_COLOR}`,
            borderRadius: "20px",
            height: "33vh",
            minWidth: "80%",
            position: "relative"
        }
    }));
    const classes = useStyles();

    return (
        <Grid item={true} className={classes.wrapper} xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.phaserCanvas} id="PHASER_WRAPPER" />
        </Grid>
    );
}
