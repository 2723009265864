import useMediaQuery from '@mui/material/useMediaQuery';
import { Header } from '.';
import { isMobile } from '../util';
import { Contact } from './Contact';
import { Experiences } from './Experiences';
import { PersonalInformation } from './PersonalInformation';
import { SkillsSimple } from './SkillsSimple';


export const MainContent: React.FC = () => {
    const isMd = useMediaQuery('(min-width:960px)')
    const isLandscape = useMediaQuery('(orientation:landscape)')

    return (
        <>
            {
                (!isMobile() || (isMobile() && !isLandscape)) && <Header name={"Contact"} path={"/contact"} />
            }
            <main>
                {
                    isMobile() && isLandscape ?
                        <Contact /> :
                        <>
                            <PersonalInformation />
                            {
                                isMobile() && !isMd && <SkillsSimple />
                            }
                            <Experiences />
                        </>
                }
            </main>
        </>
    )
}