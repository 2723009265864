import { content } from "../../Content"
import { isTouchDevice } from "../../util";
import ConfigurableImage from "./ConfigurableImage";

const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {
  active: true,
  visible: true,
  key: 'SkillRay',
};

export type RoundImageType = {
  name: string,
  fileName: string,
  percentage: number
}

export class SkillRayScene extends Phaser.Scene {
  private LIGHT_STARTING_POINT = -5000
  private LIGHT_END_POINT = 5000
  private IMAGE_TINT = 0x829fad

  private light: Phaser.GameObjects.Image | undefined;
  private bitmapMask: Phaser.GameObjects.Image | undefined;
  private overlayingImages: ConfigurableImage[] = []
  private underlayingImages: ConfigurableImage[] = []
  private allImages: ConfigurableImage[] = []
  private arrowSharp: Phaser.GameObjects.Image | undefined;
  private arrowBlurry: Phaser.GameObjects.Image | undefined;
  private text: Phaser.GameObjects.Text | undefined;

  private animationRuns = true

  constructor() {
    super(sceneConfig);
    window.addEventListener('resize', () => {
      this.scale.refresh();
      if (this.arrowBlurry) {
        this.arrowSharp!.displayWidth = this.gameWidth()
        this.arrowBlurry.displayWidth = this.gameWidth()
      }
    });
  }

  public preload() {
    content.skillLogosRound.forEach(
      logo => this.load.image(logo.name, `${logo.fileName}`)
    )
    this.load.image("transparent", `/images/skillRay/transparent.png`)
    this.load.image("arrowBlurry", `/images/skillRay/ArrowBlurryLightgrey.png`)
    this.load.image("arrowSharp", `/images/skillRay/ArrowSharpLightgrey.png`)
  }

  public create(): void {
    this.input.on('pointermove', (event: MouseEvent) => {
      this.animationRuns = false
      this.light!.x = event.x
      this.light!.y = event.y
    });

    this.input.on('gameout', () => {
      this.animationRuns = true
      this.light!.y = this.gameHeight() / 2
      this.text?.setText("")
    })

    this.light = this.add.image(this.LIGHT_STARTING_POINT, this.gameHeight() / 2, "")
      .setScale(1)
      .setVisible(false)

    this.bitmapMask = this.make.image({
      x: this.LIGHT_STARTING_POINT,
      y: this.gameHeight() / 2,
      key: 'transparent',
      add: false
    }).setScale(2);

    this.text = this.add.text(0, 0, "");

    this.bitmapMask.displayWidth = this.gameWidth() * 1.5

    this.arrowSharp = this.add.image(this.gameWidth() / 2, this.gameHeight() / 2, "arrowSharp")
      .setOrigin(0.5, 0.45)
      .setScale(0.5)
    this.arrowBlurry = this.add.image(this.gameWidth() / 2, this.gameHeight() / 2, "arrowBlurry")
      .setOrigin(0.5, 0.45)
      .setScale(0.5)

    this.arrowSharp.displayWidth = this.gameWidth()
    this.arrowBlurry.displayWidth = this.gameWidth()

    let offsetIndex = 0
    const vertical_image_offsets = [0.4, 0.60]
    content.skillLogosRound.sort(this.compare).forEach(
      (logo: RoundImageType, index: number) => {
        const x = logo.percentage * this.horizontalStep()
        const scale = this.getImageScaleforPercentage(logo.percentage)
        let offset = vertical_image_offsets[(offsetIndex += 1) % 2]

        if (offset <= 0.4) {
          offset -= index * (0.005 + 0.7 / 90)
        } else if (offset >= 0.6) {
          offset += index * (0.005 + 0.7 / 90)
        }

        const y = this.gameHeight() * offset

        const underlayingImage = this.add.existing(
          new ConfigurableImage(this, x, y, logo.name, scale, scale, 1, this.IMAGE_TINT).setOrigin(0.5)
        )
        this.underlayingImages.push(underlayingImage)

        const graphics = this.add.graphics()
        graphics.lineStyle(1, this.IMAGE_TINT, 0.6)
        graphics.lineBetween(x, y, x, this.gameHeight() / 2)

        const overlayImage = this.add.existing(
          new ConfigurableImage(this, x, y, logo.name, scale, scale, 1)
            .setName(logo.name)
            .setOrigin(0.5)
        )

        overlayImage.setInteractive().on('pointermove', () => {
          if(this.text?.text !== logo.name) {
            this.text?.setText(logo.name)
          }
        });

        overlayImage.setInteractive().on('pointerout', () => {
          if(this.text?.text !== "") {
            this.text?.setText("")
          }
        });

        this.overlayingImages.push(overlayImage)
      }
    )

    const invisibleMask = new Phaser.Display.Masks.BitmapMask(this, this.bitmapMask!)

    this.overlayingImages.forEach(
      overlayingImage => overlayingImage.mask = invisibleMask
    )

    this.arrowBlurry.mask = invisibleMask

    this.allImages = this.overlayingImages.concat(this.underlayingImages)
  }

  public update(): void {
    this.bitmapMask!.x = this.light!.x
    this.allImages.forEach(
      oi => {
        oi.scaleTo(Math.max(oi.baseScale, (this.game.scale.width - Math.abs(oi.x - this.light!.x)) / 2800))
      }
    )

    if (this.animationRuns === true) {
      this.light!.x += this.horizontalStep() * 4
    }

    if (this.light!.x >= this.LIGHT_END_POINT) {
      this.light!.x = this.LIGHT_STARTING_POINT
    }

    if (isTouchDevice()) {
      if (this.animationRuns && this.input.activePointer.isDown) {
        this.animationRuns = false
      } else if (!this.animationRuns && !this.input.activePointer.isDown) {
        this.animationRuns = true
      }
    }
  }

  private gameWidth(): integer {
    return this.game.scale.width
  }

  private gameHeight(): integer {
    return this.game.scale.height
  }

  private skillRayWidth(): integer {
    return (this.gameWidth() / 6) * 5.5
  }

  private horizontalStep(): integer {
    return this.skillRayWidth() / 100
  }

  private compare(a: RoundImageType, b: RoundImageType): integer {
    if (a.percentage < b.percentage) {
      return -1;
    }
    if (a.percentage > b.percentage) {
      return 1;
    }
    return 0;
  }

  private getImageScaleforPercentage(percentage: number): number {
    return (0.4 *
      Math.min((this.gameWidth() / 1500), 0.7) *
      (percentage / 100) + ((1 - (percentage / 100)) / 7)) * (1 + (90 - percentage) / 200)
  }
}
