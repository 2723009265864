import Phaser from "phaser";

export default class ConfigurableImage extends Phaser.GameObjects.Image {
    isGrown: boolean = false
    baseScale: number = 0.5
    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        key: string,
        scale: number = 0.5,
        baseScale: number = 0.5,
        alpha: number = 1,
        tint: number | undefined = undefined
    ) {
        super(scene, x, y, key);
        this.setAlpha(alpha)
        this.setScale(scale)
        this.baseScale = baseScale
        tint && this.setTint(tint)
    }

    scaleTo(scale: number) {
        this.setScale(scale)
    }
}